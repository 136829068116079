const cancellation_policies = [
  {
    Id: 1,
    Name: "None",
  },
  {
    Id: 2,
    Name: "Free cancellation",
  },
  {
    Id: 3,
    Name: "Book without credit card",
  },
  {
    Id: 4,
    Name: "No prepayment",
  },
];

const bed_types = [
  {
    Id: 1,
    Name: "Single",
  },
  {
    Id: 2,
    Name: "Double",
  },
  {
    Id: 3,
    Name: "Queen",
  },
  {
    Id: 4,
    Name: "King",
  },
];

const LocalStorageKeys = {
  PublicKey: "public-key",
  PrivateKey: "private-key",
  AccountAddress: "account-address",
  XummPkceJwt: "XummPkceJwt",
  pkce_state: "pkce_state",
  HotelSelectionDetails: "hotel-selection-details",
  BookingCustomer: "booking-customer",
  AiHotelSearchResult: "ai-hotel-search-result",
  MoreAiHotelSearchResult: "more-ai-hotel-search-result",
  LoginMethod: "login-method",
  PendingReservation: "pending-reservation",
  PendingHotelRegistration: "pending-hotel-registration",
  TransactionInProgress: "transaction-in-progress",
  PayWithInternalWallet: "pay-with-internal-wallet",
  SearchCategory: "search_category",
  EscrowCreateInProgress: "escrow-create-in-progress",
  TPQValue: "tpq-value",
};

const XRPLAccountEventTypes = {
  PAYMENT: "payment",
};

const LoginMethods = {
  SOCIAL: "Social",
  XRPL: "XRPL",
  ETHEREUM: "Ethereum",
  SOLANA: "Solana",
};

const PaymentResults = {
  tesSUCCESS: "tesSUCCESS",
  REJECTED: "rejected",
  ABORTED: "aborted",
  tecUNFUNDED: "tecUNFUNDED",
  tecNO_DST: "tecNO_DST",
  tecNO_PERMISSION: "tecNO_PERMISSION",
};

const ReservationStatus = {
  RESERVED: "Reserved",
  PENDING_RESERVATION: "Pending Reservation",
  CANCELLED_AND_PENDING_REFUND: "Cancelled & Pending Refund",
  CANCELLED_AND_REFUNDED: "Cancelled & Refunded",
  REJECTED_AND_PENDING_REFUND: "Rejected & Pending Refund",
  REJECTED_AND_REFUNDED: "Rejected & Refunded",
  ACCEPTED: "Accepted",
  ACCEPTED_AND_PENDING_PAYMENT: "Accepted & Pending Payment",
  COMPLETED: "Completed",
  CANCELLED_AND_REFUNDED: "Cancelled & Refunded",
};

const DestinationTags = {
  RESERVATION_PAYMENT: 1002,
};

const TransactionTypes = {
  PAYMENT: "Payment",
  ESCROW_CREATE: "EscrowCreate",
  ESCROW_CANCEL: "EscrowCancel",
  ESCROW_FINISH: "EscrowFinish",
  TRUST_SET: "TrustSet",
};

const ReservationWaitingTime = 2 * 60 * 1000;

module.exports = {
  XRPLAccountEventTypes,
  LocalStorageKeys,
  bed_types,
  cancellation_policies,
  PaymentResults,
  DestinationTags,
  LoginMethods,
  ReservationStatus,
  TransactionTypes,
  ReservationWaitingTime,
};
