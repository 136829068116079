import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import { Button } from "reactstrap";
import { ReactComponent as Apartment } from "../../Assets/Icons/SearchMenu/apartment.svg";
import { ReactComponent as Hotels } from "../../Assets/Icons/SearchMenu/hotels.svg";
import { ReactComponent as Resort } from "../../Assets/Icons/SearchMenu/resort.svg";
import { ReactComponent as Restaurant } from "../../Assets/Icons/SearchMenu/restaurant.svg";
import { ReactComponent as Villa } from "../../Assets/Icons/SearchMenu/villa.svg";
import { LocalStorageKeys } from "../../constants/constants";

function SearchMenu(props) {
  const [selectedButton, setSelectedButton] = useState("hotel");

  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.SearchCategory, "Hotels");
  }, []);

  function capitalizeWord(word) {
    if (!word) return "";
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + "s";
  }

  const handleButtonClick = (button) => {
    setSelectedButton(button);
    localStorage.removeItem("search_category");
    localStorage.setItem("search_category", capitalizeWord(button));
  };
  return (
    <>
      <div className={`${style.searchButtons}`}>
        <div className={`${style.searchButtonGroup}`}>
          <Button
            onClick={() => handleButtonClick("hotel")}
            className={
              selectedButton === "hotel"
                ? `${style.plannerbutton}`
                : `${style.buttonNotSelected}`
            }
          >
            <Hotels
              style={{ paddingRight: "10px" }}
              className={`${style.svg_img}`}
            />
            Hotels
          </Button>
          <Button
            onClick={() => handleButtonClick("restaurant")}
            className={
              selectedButton === "restaurant"
                ? `${style.plannerbutton}`
                : `${style.buttonNotSelected}`
            }
          >
            <Restaurant
              className={`${style.svg_img}`}
              style={{ paddingRight: "10px" }}
            />
            Restaurant
          </Button>
          <Button
            onClick={() => handleButtonClick("apartment")}
            className={
              selectedButton === "apartment"
                ? `${style.plannerbutton}`
                : `${style.buttonNotSelected}`
            }
          >
            <Apartment
              style={{ paddingRight: "10px" }}
              className={`${style.svg_img}`}
            />
            Apartment
          </Button>
          <Button
            onClick={() => handleButtonClick("villa")}
            className={
              selectedButton === "villa"
                ? `${style.plannerbutton}`
                : `${style.buttonNotSelected}`
            }
          >
            <Villa
              style={{ paddingRight: "10px" }}
              className={`${style.svg_img}`}
            />
            Villa
          </Button>
          <Button
            onClick={() => handleButtonClick("resort")}
            className={
              selectedButton === "resort"
                ? `${style.plannerbutton}`
                : `${style.buttonNotSelected}`
            }
          >
            <Resort
              style={{ paddingRight: "10px" }}
              className={`${style.svg_img}`}
            />
            Resorts
          </Button>
        </div>
      </div>
    </>
  );
}

export default SearchMenu;
