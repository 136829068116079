import { Web3Auth } from "@web3auth/modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import {
  CHAIN_NAMESPACES,
  UX_MODE,
  WEB3AUTH_NETWORK,
  WALLET_ADAPTERS,
} from "@web3auth/base";
import { XrplPrivateKeyProvider } from "@web3auth/xrpl-provider";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { SolanaPrivateKeyProvider } from "@web3auth/solana-provider";
import {
  LocalStorageKeys,
  LoginMethods,
  DestinationTags,
  TransactionTypes,
  PaymentResults,
} from "../constants/constants";
import { showPayQRWindow } from "./xumm-api-service";
import { store } from "../redux/store";
import {
  logoutSuccessfully,
  loginSuccessfully,
} from "../redux/LoginState/LoginStateSlice";
import CustomXrplAdapter from "../helpers/walletAdapters/XRPLAdapter";
import toast from "react-hot-toast";
import { xummLogout } from "../services-common/xumm-api-service";

const clientId = process.env.REACT_APP_WEB3AUTH_CLIENTID;

// XRPL configs
const xrplWSUrl = process.env.REACT_APP_XRPL_WS_URL;
const xrplHTTPUrl = process.env.REACT_APP_XRPL_HTTP_URL;
const xrplChainId = process.env.REACT_APP_XRPL_CHAIN_ID;
const xrplBlockExploreUrl = process.env.REACT_APP_XRPL_BLOCK_EXPLORE_URL;

// SOLANA configs
const solanaWSUrl = process.env.REACT_APP_SOLANA_WS_URL;
const solanaHTTPUrl = process.env.REACT_APP_SOLANA_HTTP_URL;
const solanaChainId = process.env.REACT_APP_SOLANA_CHAIN_ID;
const solanaBlockExploreUrl = process.env.REACT_APP_SOLANA_BLOCK_EXPLORE_URL;

// SEPOLIA configs
const infuraProjectId = process.env.REACT_APP_INFURA_PROJECT_ID;
const sepoliaWSUrl = process.env.REACT_APP_SEPOLIA_WS_URL;
const sepoliaHTTPUrl = process.env.REACT_APP_SEPOLIA_HTTP_URL;
const sepoliaChainId = process.env.REACT_APP_SEPOLIA_CHAIN_ID;
const sepoliaBlockExploreUrl = process.env.REACT_APP_SEPOLIA_BLOCK_EXPLORE_URL;

const xrpl = require("xrpl");
const xrplClient = new xrpl.Client(xrplWSUrl);

// Client connection
const connectClient = async () => {
  try {
    await xrplClient.connect();
    console.log("Connected to XRPL WebSocket client");
  } catch (error) {
    console.error("Error connecting to XRPL WebSocket client:", error);
    toast.error("Failed to connect to XRPL WebSocket client");
  }
};
connectClient();

const xrplChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.XRPL,
  chainId: xrplChainId,
  rpcTarget: xrplHTTPUrl,
  wsTarget: xrplWSUrl,
  ticker: "XRP",
  tickerName: "XRPL",
  displayName: "XRPL",
  blockExplorerUrl: xrplBlockExploreUrl,
};

const sepoliaChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: sepoliaChainId,
  rpcTarget: `${sepoliaHTTPUrl}${infuraProjectId}`,
  wsTarget: `${sepoliaWSUrl}${infuraProjectId}`,
  displayName: "SEPOLIA",
  blockExplorerUrl: sepoliaBlockExploreUrl,
  ticker: "ETH",
  tickerName: "Ethereum",
};

const solanaChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.SOLANA,
  chainId: solanaChainId, // Use 'devnet' for Solana testnet
  rpcTarget: solanaHTTPUrl,
  wsTarget: solanaWSUrl,
  ticker: "SOL",
  tickerName: "Solana",
  displayName: "SOLANA",
  blockExplorerUrl: solanaBlockExploreUrl,
};

let web3auth = null;
let privateKeyProvider = null;

export const initWeb3Auth = async (loginMethod) => {
  try {
    console.log("Initializing Web3Auth...");

    // Creating private key providers
    if (
      loginMethod === LoginMethods.XRPL ||
      loginMethod === LoginMethods.SOCIAL
    ) {
      privateKeyProvider = new XrplPrivateKeyProvider({
        config: {
          chainConfig: xrplChainConfig,
        },
      });
    } else if (loginMethod === LoginMethods.ETHEREUM) {
      privateKeyProvider = new EthereumPrivateKeyProvider({
        config: {
          chainConfig: sepoliaChainConfig,
        },
      });
    } else if (loginMethod === LoginMethods.SOLANA) {
      privateKeyProvider = new SolanaPrivateKeyProvider({
        config: {
          chainConfig: solanaChainConfig,
        },
      });
    }

    web3auth = new Web3Auth({
      clientId,
      uiConfig: {
        appName: "TripQ",
        theme: {
          primary: "darkblue",
        },
        mode: "dark",
        displayErrorsOnModal: true,
        defaultLanguage: "en",
        loginGridCol: 3,
        primaryButton: "externalLogin",
        uxMode: UX_MODE.REDIRECT,
      },
      web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
      privateKeyProvider: privateKeyProvider,
    });

    // Open login adapter
    const openloginAdapter = new OpenloginAdapter({
      loginSettings: {
        mfaLevel: "none",
      },
      adapterSettings: {
        uxMode: "redirect",
        whiteLabel: {
          defaultLanguage: "en",
        },
      },
    });

    // Configuring external wallets
    web3auth.modalConfig.adapters = [];
    if (loginMethod === LoginMethods.XRPL) {
      const customXrplAdapter = new CustomXrplAdapter();
      web3auth.configureAdapter(customXrplAdapter);
    } else if (loginMethod === LoginMethods.ETHEREUM) {
      const metamaskAdapter = new MetamaskAdapter({
        clientId,
        chainConfig: sepoliaChainConfig,
      });
      web3auth.configureAdapter(metamaskAdapter);
    }
    web3auth.configureAdapter(openloginAdapter);
    await web3auth.initModal();
    console.log("Web3Auth initialized successfully.");
    return web3auth;
  } catch (error) {
    console.error("Error initializing Web3Auth:", error);
  }
};

export const Login = async () => {
  if (!web3auth) {
    await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginMethod));
  }

  try {
    console.log("Attempting to log in...");

    if (web3auth) {
      //store.dispatch(loginSuccessfully())
      //localStorage.setItem("isLoggedIn", true)
      const res = await web3auth.connect();
      return res;
    }
  } catch (error) {
    localStorage.clear();
    throw error;
  }
};

export const Logout = async () => {
  localStorage.clear();
  store.dispatch(logoutSuccessfully());
  toast.success("logged out successfully");
  // try {
  //   localStorage.setItem("isLoggedIn", false);
  //   await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginMethod));

  //   if (
  //     localStorage.getItem(LocalStorageKeys.LoginMethod) === LoginMethods.XRPL
  //   ) {
  //     await xummLogout();
  //   } else {
  //     await web3auth.logout();
  //   }

  //   localStorage.clear();
  //   store.dispatch(logoutSuccessfully());
  //   toast.success("logged out successfully");
  // } catch (error) {
  //   console.error("Error during logout:", error);
  // }
};

export const authenticateUser = async () => {
  if (!web3auth) {
    console.log("Web3Auth not initialized yet");
    return;
  }
  try {
    console.log("Authenticating user account...", web3auth.provider);

    const idToken = await web3auth.authenticateUser();
    console.log("ID Token:", idToken);
  } catch (error) {
    console.error("Error during authentication:", error);
  }
};

export const getUserInfo = async () => {
  if (!web3auth) {
    await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginMethod));
  }
  try {
    const user = await web3auth.getUserInfo();
    console.log("User Info:", user);
    return user;
  } catch (error) {
    console.error("Error fetching user info:", error);
  }
};

export const getAccounts = async () => {
  if (!web3auth || !web3auth.provider) {
    await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginMethod));
  }

  try {
    const accounts = await web3auth.provider.request({
      method: "xrpl_getAccounts",
    });
    return accounts;
  } catch (error) {
    console.error("Error fetching accounts or account info:", error);
  }
};

export const getBalance = async (accountAddress) => {
  return await xrplClient.getXrpBalance(accountAddress);
};

export const sendTransaction = async (
  sourceAddress,
  destinationAddress,
  price,
  finishAfterTime,
  cancelAfterTime,
  transactionType
) => {
  try {
    const response = await showPayQRWindow(
      sourceAddress,
      destinationAddress,
      price,
      finishAfterTime,
      cancelAfterTime,
      DestinationTags.RESERVATION_PAYMENT,
      "XRP",
      null,
      transactionType,
      0
    );
    return response;
  } catch (error) {
    console.error("Error sending transaction:", error);
    throw error;
  }
};

async function getLastLedgerSequence() {
  if (!xrplClient.isConnected()) {
    await connectClient();
  }
  const ledger = await xrplClient.request({
    command: "ledger",
    ledger_index: "validated",
  });
  return ledger.result.ledger_index + 50; // Add a reasonable buffer to the current ledger index
}

async function getAccountSequence(account) {
  if (!xrplClient.isConnected()) {
    await connectClient();
  }
  const accountInfo = await xrplClient.request({
    command: "account_info",
    account: account,
  });
  return accountInfo.result.account_data.Sequence;
}

export const createTrustLineSocial = async (userAccountAddress) => {
  let web3auth = await initWeb3Auth(
    localStorage.getItem(LocalStorageKeys.LoginMethod)
  );
  if (!web3auth) {
    throw new Error("failed to initialize web3auth");
  }
  const lastLedgerSequence = await getLastLedgerSequence();
  const currency = process.env.REACT_APP_TPQ_CURRENCY_HEX;
  const issuerAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;
  const trustLineLimit = process.env.REACT_APP_TPQ_TRUSTLINE_LIMIT;

  if (!xrplClient.isConnected()) {
    await connectClient();
  }

  const tx = {
    TransactionType: "TrustSet",
    LimitAmount: {
      currency: currency,
      issuer: issuerAddress,
      value: trustLineLimit,
    },
    Account: userAccountAddress,
    LastLedgerSequence: lastLedgerSequence,
  };

  const result = await web3auth.provider.request({
    method: "xrpl_submitTransaction",
    params: {
      transaction: tx,
    },
  });

  if (result.result.engine_result === PaymentResults.tesSUCCESS) {
    console.log("Trustline initiated successfully");
    return true;
  } else {
    console.error("Trustline initiation failed");
    return false;
  }
};

export const reduceBalanceFromSOCIAL = async () => {
  const TPQ_REDUCE_AMOUNT = "10";
  const destinationAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;
  const sourceAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);
  const lastLedgerSequence = await getLastLedgerSequence();
  const accountSequence = await getAccountSequence(sourceAddress);
  const currency = process.env.REACT_APP_TPQ_CURRENCY_HEX;
  const issuerAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;

  let web3auth = await initWeb3Auth(
    localStorage.getItem(LocalStorageKeys.LoginMethod)
  );

  if (!web3auth) {
    throw new Error("failed to initialize web3auth");
  }

  const tx = {
    TransactionType: "Payment",
    Amount: {
      currency: currency,
      issuer: issuerAddress,
      value: TPQ_REDUCE_AMOUNT,
    },
    Account: sourceAddress,
    Destination: destinationAddress,
    LastLedgerSequence: lastLedgerSequence,
  };

  const result = await web3auth.provider.request({
    method: "xrpl_submitTransaction",
    params: {
      transaction: tx,
    },
  });

  return result;
};

export const checkAccountActivation = async (account) => {
  try {
    if (!xrplClient.isConnected()) {
      await xrplClient.connect();
    }

    try {
      const accountInfo = await xrplClient.request({
        command: "account_info",
        account: account,
      });
      return true;
    } catch (error) {
      console.log("Error checking account activation:", error);
      return false;
    }
  } catch (error) {
    console.log("Error checking account activation:", error);
    return false;
  }
};
