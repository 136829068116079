import { FaTimes } from "react-icons/fa";
import "./PaymentSelectionTopUpSocial.scss";
import { Button } from "reactstrap";

const PaymentSelectionTopUpSocial = ({
  onClose,
  proceedToPayWithInternalWallet,
  proceedToPayWithDigitalCurrency,
}) => {
  const handleProceedToPayWithInternalWallet = () => {
    proceedToPayWithInternalWallet();
    onClose();
  };
  const handleProceedToPayWithDigitalCurrency = () => {
    proceedToPayWithDigitalCurrency();
    onClose();
  };
  return (
    <section className="top-up-modal-overlay" onClick={onClose}>
      <div className="top-up-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
        <FaTimes style={{ fontSize: '18px' }} />
        </button>
        <div className="modal-title">Which payment method do you prefer?</div>
        <div className="modal-content">
          <Button
            className="wallet-button"
            onClick={handleProceedToPayWithInternalWallet}
          >
            Proceed with Internal Wallet
          </Button>
          <Button
            className="wallet-button"
            onClick={handleProceedToPayWithDigitalCurrency}
          >
            Proceed with External Wallet
          </Button>
        </div>
      </div>
    </section>
  );
};

export default PaymentSelectionTopUpSocial;
