const properties = [
  {
    name: "Kandy",
    image: "/Assets/Images/dashboard/explore/kandy.webp",
    number: 1365,
  },
  {
    name: "Ella",
    image: "/Assets/Images/dashboard/explore/Ella.webp",
    number: 784,
  },
  {
    name: "Sigiriya",
    image: "/Assets/Images/dashboard/explore/Sigiriya.webp",
    number: 439,
  },
];

export default properties;
