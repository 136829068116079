import React from "react";
import style from "./index.module.scss";

import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  Button,
} from "reactstrap";

import StarRatings from "react-star-ratings";

function TopHotelCard(props) {
  return (
    <Card style={{ padding: "10px",border: "none" }}>
        <CardImg
          src={props.hotel.image}
          alt="offer"
          height="200"
          className={`${style.hotelImage}`}
        ></CardImg>
        <CardBody className="border-0" style={{ paddingLeft: "0px", paddingRight: "0px", border: "none" }}>
          <CardTitle tag="h5">{props.hotel.name}</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            {props.hotel.location}
          </CardSubtitle>
          
          <div className={` ${style.stars}`}>
            <StarRatings
              rating={props.hotel.rating}
              starRatedColor="gold"
              starSpacing="1px"
              starDimension="16px"
              numberOfStars={5}
              name="rating"
            />
          </div>
          <p className={` ${style.rating_count} ${style.rating}`}>
            {props.hotel.ratingCount} ratings
          </p>

          <Button className={`secondaryButton ${style.dealButton}`}>
            View Deal
          </Button>
        </CardBody>
    </Card>
  );
}

export default TopHotelCard;
