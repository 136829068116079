import React from "react";
import CustomerRegistration from "../components/CustomerRegistration/CustomerRegistration";

const RegisterCustomer = () => {
  return (
    <div>
      <CustomerRegistration />
    </div>
  );
};

export default RegisterCustomer;
