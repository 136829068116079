const facilitiesData = [
    {
        Id: 1,
        Name: "Free WiFi",
        Description: "Description"
    },
    {
        Id: 2,
        Name: "Swimming Pool",
        Description: "Description"

    },
    {
        Id: 3,
        Name: "Fitness Center",
        Description: "Description"

    },
    {
        Id: 4,
        Name: "Room Service",
        Description: "Description"

    },
    {
        Id: 5,
        Name: "Spa & Wellness",
        Description: "Description"

    },
    {
        Id: 6,
        Name: "Family Room",
        Description: "Description"

    },
    {
        Id: 7,
        Name: "Pet friendly",
        Description: "Description"

    },
    {
        Id: 8,
        Name: "Disabled access",
        Description: "Description"

    },
    {
        Id: 9,
        Name: "Restaurant",
        Description: "Description"

    },
    {
        Id: 10,
        Name: "Parking",
        Description: "Description"
    }
    ,
    {
        Id: 11,
        Name: "Air Port Shuttle",
        Description: "Description"
    }
    ,
    {
        Id: 12,
        Name: "Bar",
        Description: "Description"
    }
    
]

export default facilitiesData;
