import { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  xummAuthorize,
  xummLogout,
} from "../../services-common/xumm-api-service";
import { Nav } from "react-bootstrap";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Logout } from "../../services-common/web3auth-service";
import { LocalStorageKeys } from "../../constants/constants";

const isUnderConstruction = process.env.REACT_APP_IS_UNDER_CONSTRUCTION
  ? process.env.REACT_APP_IS_UNDER_CONSTRUCTION == 1
    ? true
    : false
  : false;
const underConstructionMsg =
  process.env.REACT_APP_UNDER_CONSTRUCTION_MESSAGE ?? "";

function NavBar(props) {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.loginState);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const isCustomer = localStorage.getItem("customer");

  const login = async () => {
    try {
      if (!loginState.isLoggedIn) {
        navigate(`/signin`);
      }
      // await xummAuthorize();
    } catch (e) {
      console.log(e);
    }
  };

  const openLoginModal = () => {
    setLoginOpen(true);
  };

  const closeLoginModal = () => {
    setLoginOpen(false);
  };

  const goToMyTransactionsPage = () => {
    navigate("/my-transactions");
  };

  const navigateToManageBookings = () => {
    navigate("/manage-bookings");
  };

  const logout = async () => {
    await Logout();
    navigate("/");
  };

  return (
    <>
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="bg-body-tertiary cus_navbar"
        >
          <Container>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              style={{ backgroundColor: "#fbb725" }}
            />
            <Navbar.Brand href="/">
              <img
                src="/Assets/Images/TripQ.png"
                width="200"
                height="70"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
          </Container>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className={`ms-auto`}>
              <Button
                variant="outline-warning"
                className={`list_button `}
                onClick={() => navigate("/list-property")}
              >
                My Properties
              </Button>
              <Button
                variant="outline-warning"
                style={{ marginLeft: "5px" }}
                className={`list_button `}
                onClick={() => navigate("/my-reservations")}
              >
                My Reservations
              </Button>
              {loginState.isLoggedIn ? (
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggle}
                  direction={"down"}
                >
                  <DropdownToggle
                    caret
                    tag="button"
                    className="btn btn-outline-warning"
                    style={{
                      marginLeft: "5px",
                      backgroundColor: "transparent",
                      border: "none",
                      outline: "none",
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} size="lg" color="white" />
                  </DropdownToggle>
                  <DropdownMenu style={{ marginTop: " 15px" }}>
                    <DropdownItem text className="address-text">
                      {localStorage.getItem(LocalStorageKeys.AccountAddress)}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => goToMyTransactionsPage()}>
                      My Account
                    </DropdownItem>
                    <DropdownItem onClick={() => navigateToManageBookings()}>
                      Manage Bookings
                    </DropdownItem>
                    <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <Button
                  style={{
                    marginLeft: "5px",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={() => login()}
                >
                  <FontAwesomeIcon icon={faUser} size="lg" color="white" />
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
}

export default NavBar;