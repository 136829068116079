import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isTopUpWithDialogModelOpen: false,
};

const topUpModalWithDialogSlice = createSlice({
  name: "topUpModalWithDialog",
  initialState,
  reducers: {
    openTopUpWithDialogModal(state) {
      state.isTopUpWithDialogModelOpen = true;
    },
    closeTopUpWithDialogModal(state) {
      state.isTopUpWithDialogModelOpen = false;
    },
  },
});

export const { openTopUpWithDialogModal, closeTopUpWithDialogModal } =
  topUpModalWithDialogSlice.actions;
export default topUpModalWithDialogSlice.reducer;
