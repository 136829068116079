import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isTopUpModalOpen: false,
};

const topUpModalSlice = createSlice({
  name: "topUpModal",
  initialState,
  reducers: {
    openTopUpModal(state) {
      state.isTopUpModalOpen = true;
    },
    closeTopUpModal(state) {
      state.isTopUpModalOpen = false;
    },
  },
});

export const { openTopUpModal, closeTopUpModal } = topUpModalSlice.actions;
export default topUpModalSlice.reducer;
