import {
  BaseAdapter,
  ADAPTER_CATEGORY,
  ADAPTER_STATUS,
  WalletInitializationError,
} from "@web3auth/base";
import { Client, dropsToXrp } from "xrpl";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { XrplPrivateKeyProvider } from "@web3auth/xrpl-provider";
import {
  xummAuthorize,
  xummLogout,
} from "../../services-common/xumm-api-service";
import { LocalStorageKeys } from "../../constants/constants";

let xumm = null;
let xrplProvider;

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.XRPL,
  chainId: "0x1",
  rpcTarget: "https://testnet.xrpl-labs.com/",
  wsTarget: "wss://testnet.xrpl-labs.com/",
  ticker: "XRP",
  tickerName: "XRPL",
  displayName: "xrpl testnet",
  blockExplorerUrl: "https://testnet.xrpl.org",
};

class XrplAdapter extends BaseAdapter {
  constructor() {
    super();
    this.name = "Xaman";
    this.adapterNamespace = "xrpl";
    this.type = ADAPTER_CATEGORY.EXTERNAL;
    this.status = ADAPTER_STATUS.NOT_READY;
    this.wallet = null;
    this.connector = null;
    this.client = new Client("wss://testnet.xrpl-labs.com/");
    this.provider = null;
    this.uiConfig = {
      loginButton: {
        logo: "https://firebasestorage.googleapis.com/v0/b/voyagelankav1.appspot.com/o/hotel_images%2FThe%20Galle%20Face%20Hotel%2FGF1%20(1).jpg?alt=media&token=ae3f6bdf-b95c-47be-b50b-3136cec11d1c", // Add your image URL here
        name: "Login with Xaman Wallet", // Custom button name
        description: "Connect to XRPL with Xaman Wallet", // Description below button
      },
    };
  }

  async init() {
    try {
      await this.client.connect();
      this.status = ADAPTER_STATUS.READY;
      this.emit(ADAPTER_STATUS.READY);
    } catch (error) {
      this.status = ADAPTER_STATUS.ERRORED;
      this.emit(ADAPTER_STATUS.ERRORED, error);
      throw WalletInitializationError.notInitializedError(
        "XRPL Client connection failed"
      );
    }
  }

  async connect() {
    try {
      const res = await xummAuthorize();
      if (res) {
        console.log(localStorage.getItem(LocalStorageKeys.AccountAddress));

        // Setting up the provider
        xrplProvider = new XrplPrivateKeyProvider({
          config: {
            chainConfig: chainConfig,
          },
        });

        this.wallet = localStorage.getItem(LocalStorageKeys.AccountAddress);
        this.provider = xrplProvider;
        this.status = ADAPTER_STATUS.CONNECTED;

        this.emit(ADAPTER_STATUS.CONNECTED, {
          provider: this.provider,
          wallet: this.wallet,
        });
        return true;
      } else {
        throw new Error("Xumm authorization failed");
      }
    } catch (e) {
      this.status = ADAPTER_STATUS.ERRORED;
      this.emit(ADAPTER_STATUS.ERRORED, e);
      throw WalletInitializationError.notConnectedError(
        "Wallet connection failed"
      );
    }
  }

  async logout() {
    console.log("Custom XRPL Adapter: Logging out...");
    try {
      await xummLogout();
      //await this.client.disconnect();
      this.status = ADAPTER_STATUS.DISCONNECTED;
      this.wallet = null;
      this.provider = null;
      this.emit("disconnected");
    } catch (error) {
      this.emit("error", error);
      throw error;
    }
  }

  async getAccounts() {
    if (this.provider) {
      return [this.provider.account.address];
    } else {
      throw WalletInitializationError.notConnectedError("Wallet not connected");
    }
  }

  async getBalance() {
    if (this.provider) {
      const accountInfo = await this.provider.client.request({
        command: "account_info",
        account: this.provider.account.address,
        ledger_index: "validated",
      });
      return dropsToXrp(accountInfo.result.account_data.Balance);
    } else {
      throw WalletInitializationError.notConnectedError("Wallet not connected");
    }
  }

  async signTransaction(tx) {
    if (this.provider) {
      const signedTx = await xumm.payload.createAndSign(tx);
      return signedTx;
    } else {
      throw WalletInitializationError.notConnectedError("Wallet not connected");
    }
  }

  async sendTransaction(signedTx) {
    if (this.provider && signedTx) {
      const response = await this.provider.client.submitAndWait(signedTx);
      return response;
    } else {
      throw new Error("Client not initialized or invalid transaction data.");
    }
  }
}

export default XrplAdapter;
