import { FaTimes } from "react-icons/fa";
import "./TopUpWithDialogModel.scss";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const TopUpWithDialogModel = ({ onClose, isUserAccountActivated }) => {
  // const [amount, setAmount] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // if (!amount || amount <= 0) {
    //   toast.error("Please enter a valid amount.");
    //   setIsLoading(false);
    //   return;
    // }

    // if (!isUserAccountActivated && amount < 10) {
    //   toast.error("Minimum top-up amount is 10 XRP.");
    //   setIsLoading(false);
    //   return;
    // }

    try {
      console.log("not implemented yet!");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  // const handleAmountFieldChange = (e) => {
  //   const inputValue = e.target.value;

  //   // Regular expression to check if the value is a positive decimal number
  //   const isPositiveDecimal =
  //     /^\d*\.?\d*$/.test(inputValue) &&
  //     (inputValue === "" || parseFloat(inputValue) > 0);

  //   if (isPositiveDecimal) {
  //     setAmount(inputValue);
  //   }
  // };

  const hadnleClick = (e) => {
    if (isLoading) return;
    onClose();
  };

  return (
    <section className="top-up-modal-overlay" onClick={hadnleClick}>
      <form
        className="top-up-modal"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
        {/* <div className="modal-title">Top-Up with prepaid card</div> */}
        <div className="modal-content">
          <label>
            Pre paid card number
            <input
              type="text"
              value={cardNumber}
              required
              placeholder="type card number here..."
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </label>
          {/* <label>
            Amount
            <input
              type="number"
              value={amount}
              placeholder="Enter amount"
              min="0" // Ensures only positive values can be entered
              step="any" // Allows for decimal values, if needed
              required
              onChange={handleAmountFieldChange}
            />
          </label> */}
        </div>
        <div className="modal-actions">
          <button className="submit-button" disabled={isLoading}>
            {isLoading ? "Processing..." : "Submit"}
          </button>
        </div>
      </form>
    </section>
  );
};

export default TopUpWithDialogModel;
