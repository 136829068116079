import ContractService from "../services-common/contract-service";
import constants from "../constants";

export default class ReservationService {
  contractService = ContractService.instance;

  async getReservations(
    id = 0,
    walletAddress = null,
    hotelId = 0,
    date = null
  ) {
    const filterObj = {};
    if (id > 0) {
      filterObj.Id = id;
    }
    if (walletAddress && walletAddress.length > 10) {
      filterObj.WalletAddress = walletAddress;
    }
    if (hotelId > 0) {
      filterObj.HotelId = hotelId;
    }
    if (date) {
      filterObj.date = date;
    }

    const submitObject = {
      type: constants.RequestTypes.RESERVATION,
      subType: constants.RequestSubTypes.GET_RESERVATIONS,
      data: { filters: filterObj },
    };
    try {
      console.log(submitObject);
      const res = await this.contractService.submitReadRequest(submitObject);
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  // Function to get reservations list by hotel owner's wallet address
  async getReservationsByHotelOwnerAddress(hotelOwnerWalletAddress) {
    const filterObj = {};

    if (hotelOwnerWalletAddress && hotelOwnerWalletAddress.length > 10) {
      filterObj.HotelOwnerWalletAddress = hotelOwnerWalletAddress;
    }

    const submitObject = {
      type: constants.RequestTypes.RESERVATION,
      subType:
        constants.RequestSubTypes
          .GET_RESERVATIONS_By_Hotel_Owner_Wallet_Address,
      data: { filters: filterObj },
    };
    try {
      const res = await this.contractService.submitReadRequest(submitObject);
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async makeReservation(data) {
    const submitObj = {
      type: constants.RequestTypes.RESERVATION,
      subType: constants.RequestSubTypes.MAKE_RESERVATIONS,
      data: data,
    };

    let result;
    try {
      result = await this.contractService.submitInputToContract(submitObj);
    } catch (e) {
      console.log(e);
      throw e;
    }

    return result;
  }

  async cancelReservation(data) {
    const submitObj = {
      type: constants.RequestTypes.RESERVATION,
      subType: constants.RequestSubTypes.CANCEL_RESERVATION,
      data: data,
    };

    let result;
    try {
      result = await this.contractService.submitInputToContract(submitObj);
    } catch (e) {
      console.log(e);
      throw e;
    }

    return result;
  }

  async getPendingRefundReservations() {
    const filterObj = {};
    const submitObj = {
      type: constants.RequestTypes.RESERVATION,
      subType: constants.RequestSubTypes.GET_PENDING_REFUND_RESERVATIONS,
      data: { filters: filterObj },
    };

    let result;
    try {
      result = await this.contractService.submitReadRequest(submitObj);
    } catch (e) {
      console.log(e);
      throw e;
    }

    return result;
  }

  async updateReservationStatus(data) {
    const submitObj = {
      type: constants.RequestTypes.RESERVATION,
      subType: constants.RequestSubTypes.UPDATE_RESERVATION_STATUS,
      data: data,
    };

    let result;
    try {
      result = await this.contractService.submitInputToContract(submitObj);
    } catch (e) {
      console.log(e);
      throw e;
    }

    return result;
  }
}
