import React, { useEffect } from "react";
import { Row, Col, Label, Button } from "reactstrap";
import Card1 from "../../layout/Card";
import "./styles.scss";
import { LoginMethods } from "../../constants/constants";
import { LocalStorageKeys } from "../../constants/constants";
import { store } from "../../redux/store";
import { loginSuccessfully } from "../../redux/LoginState/LoginStateSlice";
import {
  initWeb3Auth,
  Login,
  getAccounts,
  createTrustLineSocial,
} from "../../services-common/web3auth-service";
import { useNavigate } from "react-router-dom";
import { setShowScreenLoader } from "../../redux/screenLoader/ScreenLoaderSlice";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import UserService from "../../services-domain/user-service-copy";

const SignIn = () => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.loginState);
  const userService = new UserService();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Initializing web3auth
  useEffect(() => {
    store.dispatch(setShowScreenLoader(true));
    const initializeWeb3Auth = async () => {
      try {
        const loginType = localStorage.getItem(LocalStorageKeys.LoginMethod);

        if (loginType) {
          await initWeb3Auth(
            localStorage.getItem(LocalStorageKeys.LoginMethod)
          );

          if (
            loginType === LoginMethods.XRPL ||
            loginType === LoginMethods.SOCIAL
          ) {
            const accounts = await getAccounts();

            if (accounts && accounts.length > 0) {
              // store.dispatch(loginSuccessfully());
              localStorage.setItem(
                LocalStorageKeys.AccountAddress,
                accounts[0]
              );

              try {
                const responseGetUser =
                  await userService.getUserByWalletAddress(accounts[0]);
                if (!responseGetUser) {
                  try {
                    userService.registerUser({
                      walletAddress: accounts[0],
                      loginMethod: "Social",
                    });
                  } catch (e) {
                    console.log("error", e);
                    throw e;
                  }
                }
              } catch (e) {
                console.log(e);
                toast.error("Error registering user");
                return;
              }

              if (localStorage.getItem(LocalStorageKeys.PendingReservation)) {
                localStorage.setItem(
                  LocalStorageKeys.PendingReservation,
                  false
                );
                navigate("/make-reservations");
              } else if (
                localStorage.getItem(LocalStorageKeys.PendingHotelRegistration)
              ) {
                localStorage.setItem(
                  LocalStorageKeys.PendingReservation,
                  false
                );
                navigate("/register-hotel");
              } else {
                toast.success("logged in successfully");

                navigate("/");
              }
              store.dispatch(loginSuccessfully(accounts[0]));
            }
          } else if (loginState) {
            store.dispatch(loginSuccessfully());
            toast.success("logged in successfully....");
            navigate("/");
          }

          console.log("Web3Auth initialized");
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again later.");
        localStorage.clear();
        console.error("Error initializing Web3Auth:", error);
      } finally {
        store.dispatch(setShowScreenLoader(false));
      }
    };

    initializeWeb3Auth();
  }, []);

  const handleLogin = async (method) => {
    localStorage.setItem(LocalStorageKeys.LoginMethod, method);

    try {
      await initWeb3Auth(method);
      const loginResponse = await Login();

      if (loginResponse) {
        store.dispatch(loginSuccessfully());
        toast.success("You have successfully logged in.");

        // Handle pending reservations
        if (localStorage.getItem(LocalStorageKeys.PendingReservation)) {
          localStorage.setItem(LocalStorageKeys.PendingReservation, false);
          navigate("/make-reservations");
        } else {
          navigate("/");
        }
      } else {
        console.log("Login failed or was cancelled.");
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <div className="signin-box">
        <Card1 className="card1">
          <Row>
            <Col md={12} className="text-title">
              <Label className="title_1">Login </Label>
              <div className="title_4 ">
                {" "}
                Pick your preferred way to connect to TripQ with social logins
                or wallet integration
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Button
                className="button-with-image"
                onClick={() => handleLogin(LoginMethods.SOCIAL)}
              >
                <img
                  title="Social Logins"
                  alt="Social Logins"
                  src="/Assets/Images/Blockchains/sociallogins.png"
                />
              </Button>
            </Col>
            <Col md={3}>
              <Button
                className="button-with-image"
                onClick={() => handleLogin(LoginMethods.XRPL)}
              >
                <img
                  title="XRPL"
                  alt="XRPL"
                  src="/Assets/Images/Blockchains/xrpl.png"
                />
              </Button>
            </Col>
            <Col md={3}>
              <Button
                className="button-with-image"
                onClick={() => handleLogin(LoginMethods.ETHEREUM)}
              >
                <img
                  title="Ethereum"
                  alt="Ethereum"
                  src="/Assets/Images/Blockchains/ethereum.png"
                />
              </Button>
            </Col>

            <Col md={3}>
              <Button
                className="button-with-image"
                onClick={() => handleLogin(LoginMethods.SOLANA)}
              >
                <img
                  title="Solana"
                  alt="Solana"
                  src="/Assets/Images/Blockchains/solana.png"
                />
              </Button>
            </Col>
          </Row>
        </Card1>
      </div>
    </>
  );
};

export default SignIn;
