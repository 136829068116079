import { configureStore } from "@reduxjs/toolkit";
import visibleReducer from "./visibility/visibleSlice";
import registerCustomerReducer from "./registerCustomer/registerCustomerSlice";
import transactionListenerReducer from "./transactionListener/transactionListenerSlice";
import loginStateReducer from "./LoginState/LoginStateSlice";
import selectionDetailsReducer from "./SelectionDetails/SelectionDetailsSlice";
import ScreenLoaderReducer from "./screenLoader/ScreenLoaderSlice";
import AiHotelSearchStateReducer from "./AiHotelSearchState/AiHotelSearchStateSlice";
import bookingCustomerReducer from "./BookingCustomer/BookingCustomerSlice";
import MoreAiHotelSearchStateReducer from "./AiHotelSearchState/MoreAiSearchStateSlice";
import topUpModalReducer from "./TopUpModel/topUpModalSlice";
import paymentSelectionTopUpSocialReducer from "./PaymentSelectionTopUpSocial/PaymentSelectionTopUpSocialSlice";
import topUpWithDialogModelReducer from "./TopUpWithDialogModel/topUpWithDialogModelSlice";

// Load loginState from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("loginState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("Could not load state", err);
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("loginState", serializedState);
  } catch (err) {
    console.error("Could not save state", err);
  }
};

const preloadedState = {
  loginState: loadState(),
};

export const store = configureStore({
  reducer: {
    visibility: visibleReducer,
    registerCustomer: registerCustomerReducer,
    listenedTransactions: transactionListenerReducer,
    loginState: loginStateReducer,
    selectionDetails: selectionDetailsReducer,
    screenLaoder: ScreenLoaderReducer,
    AiHotelSearchState: AiHotelSearchStateReducer,
    MoreAiHotelSearchState: MoreAiHotelSearchStateReducer,
    bookingCustomer: bookingCustomerReducer,
    topUpModal: topUpModalReducer,
    paymentSelectionTopUpSocial: paymentSelectionTopUpSocialReducer,
    topUpModalWithDialog: topUpWithDialogModelReducer,
  },
  preloadedState,
});

// Subscribe to store updates
store.subscribe(() => {
  const state = store.getState();
  saveState(state.loginState);
});
