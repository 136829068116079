import "./styles.scss";
import Container from "react-bootstrap/Container";
import { Row, Col, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import ReservationService from "../../services-domain/reservation-service";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import {
  LocalStorageKeys,
  ReservationStatus,
  TransactionTypes,
  DestinationTags,
  LoginMethods,
  PaymentResults,
} from "../../constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../modals/ConfirmationModal/ConfirmationModal";
import toast from "react-hot-toast";
import { store } from "../../redux/store";
import { setShowScreenLoader } from "../../redux/screenLoader/ScreenLoaderSlice";
import {
  cancelEscrowForSocial,
  reduceBalanceFromXRPL,
  showPayQRWindow,
} from "../../services-common/xumm-api-service";
import { reduceBalanceFromSOCIAL } from "../../services-common/web3auth-service";

const ManageBookings = () => {
  const [reservationList, setReservationList] = useState([]);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [reservationId, setReservationId] = useState(0);

  const hotelOwnerAddress = localStorage.getItem(
    LocalStorageKeys.AccountAddress
  );
  const reservationService = new ReservationService();

  // Get reservations list by hotel owner's wallet address
  const getReservationListByHotelOwnerAddress = async (filteringAddress) => {
    const reservationsRes =
      await reservationService.getReservationsByHotelOwnerAddress(
        filteringAddress
      );
    return reservationsRes;
  };

  const openRejectionModal = (reservationId) => {
    setReservationId(reservationId);
    setModalText("Please Confirm Reservation Rejection");
    setShowRejectionModal(true);
  };

  const openCompletionModal = (reservationId) => {
    setReservationId(reservationId);
    setModalText("Please Confirm Reservation Completion");
    setShowCompletionModal(true);
  };

  const closeModal = () => {
    setShowCompletionModal(false);
    setShowRejectionModal(false);
  };

  const handleRejection = async () => {
    closeModal();
    store.dispatch(setShowScreenLoader(true));
    const isSlocalLogin =
      localStorage.getItem(LocalStorageKeys.LoginMethod) ===
      LoginMethods.SOCIAL;

    if (isSlocalLogin) {
      const selectedRes = reservationList.find(
        (res) => res.Id === reservationId
      );
      try {
        const answer = await cancelEscrowForSocial(selectedRes);
        if (
          answer &&
          answer.result &&
          answer.result.engine_result === PaymentResults.tesSUCCESS
        ) {
          const data = {
            ReservationId: reservationId,
            Status: ReservationStatus.REJECTED_AND_REFUNDED,
          };
          const result = await reservationService.cancelReservation(data);
          if (result) {
            toast.success(
              "Reservation cancelled successfully. Amount has been refunded"
            );
          }
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          toast.error("Error cancelling reservation");
          console.error("Error cancelling reservation:", answer);
        }
        // window.location.reload();
        store.dispatch(setShowScreenLoader(false));
      } catch (error) {
        console.error("Error occurred in rejecting reservation:", error);
        store.dispatch(setShowScreenLoader(false));
      }
    } else {
      try {
        const updateData = {
          reservationList: [reservationId],
          status: ReservationStatus.REJECTED_AND_PENDING_REFUND,
        };

        const result = await reservationService.updateReservationStatus(
          updateData
        );

        if (result) {
          getReservationListByHotelOwnerAddress(hotelOwnerAddress).then(
            async (res) => {
              if (res && res.length > 0) {
                setReservationList(res);
              }
            }
          );
          store.dispatch(setShowScreenLoader(false));
          toast.success("Reservation rejected successfully");
        }
        store.dispatch(setShowScreenLoader(false));
      } catch (error) {
        store.dispatch(setShowScreenLoader(false));
        console.error("Error occurred in rejecting reservation:", error);
      }
    }
  };

  const handleAcceptance = async (
    reservationId,
    checkIn,
    escrowSequence,
    customerWalletAddress
  ) => {
    setReservationId(reservationId);
    store.dispatch(setShowScreenLoader(true));
    const isSocialLogin =
      localStorage.getItem(LocalStorageKeys.LoginMethod) ===
      LoginMethods.SOCIAL;
    try {
      // check check-in date
      const checkinDate = new Date(checkIn);
      const today = new Date();

      const finishAfterDate = new Date(checkIn);
      finishAfterDate.setDate(checkinDate.getDate() - 7);

      if (finishAfterDate > today) {
        console.log("Schedule finish transaction");
        // schedule finish transaction
        const updateData = {
          reservationList: [reservationId],
          status: ReservationStatus.ACCEPTED_AND_PENDING_PAYMENT,
        };

        await reservationService.updateReservationStatus(updateData);
        if (isSocialLogin) {
          await reduceBalanceFromSOCIAL();
        } else {
          await reduceBalanceFromXRPL();
        }
        getReservationListByHotelOwnerAddress(hotelOwnerAddress).then(
          async (res) => {
            if (res && res.length > 0) {
              setReservationList(res);
              store.dispatch(setShowScreenLoader(false));
            }
          }
        );
        toast.success("Reservation accepted successfully");
        store.dispatch(setShowScreenLoader(false));
      } else {
        console.log("Finish transaction immediately");
        // immediately  finish transaction
        try {
          const response = await showPayQRWindow(
            customerWalletAddress,
            hotelOwnerAddress,
            0,
            "",
            "",
            DestinationTags.RESERVATION_PAYMENT,
            "",
            null,
            TransactionTypes.ESCROW_FINISH,
            escrowSequence
          );
          if (response.transactionResult === "tesSUCCESS") {
            store.dispatch(setShowScreenLoader(true));
            const updateData = {
              reservationList: [reservationId],
              status: ReservationStatus.ACCEPTED,
            };
            await reservationService.updateReservationStatus(updateData);
            await reduceBalanceFromXRPL();
            getReservationListByHotelOwnerAddress(hotelOwnerAddress).then(
              async (res) => {
                if (res && res.length > 0) {
                  setReservationList(res);
                  store.dispatch(setShowScreenLoader(false));
                }
              }
            );
            toast.success("Reservation accepted successfully");
          }
        } catch (error) {
          console.error(
            "Error occurred in finishing escrow transaction:",
            error
          );
          store.dispatch(setShowScreenLoader(false));
          throw error;
        }
      }

      store.dispatch(setShowScreenLoader(false));
    } catch (error) {
      store.dispatch(setShowScreenLoader(false));
      console.error("Error rejecting reservation:", error);
    }
  };

  const handleCompletion = async () => {
    closeModal();
    store.dispatch(setShowScreenLoader(true));

    try {
      const updateData = {
        reservationList: [reservationId],
        status: ReservationStatus.COMPLETED,
      };

      const result = await reservationService.updateReservationStatus(
        updateData
      );

      if (result) {
        getReservationListByHotelOwnerAddress(hotelOwnerAddress).then(
          async (res) => {
            if (res && res.length > 0) {
              setReservationList(res);
            }
          }
        );
        store.dispatch(setShowScreenLoader(false));
        toast.success("Reservation completed successfully");
      }
    } catch (error) {
      store.dispatch(setShowScreenLoader(false));
      console.error("Error occurred in completing reservation:", error);
    }
  };

  useEffect(() => {
    store.dispatch(setShowScreenLoader(true));
    let reservationListToBeUpdated = [];
    getReservationListByHotelOwnerAddress(hotelOwnerAddress)
      .then(async (res) => {
        if (res && res.length > 0) {
          setReservationList(res);

          for (const reservation of res) {
            if (
              reservation.Status === ReservationStatus.PENDING_RESERVATION &&
              new Date(reservation.FinishAfterTime) < new Date()
            ) {
              reservationListToBeUpdated.push(reservation.Id);
            }
          }

          if (reservationListToBeUpdated.length > 0) {
            const updateData = {
              reservationList: reservationListToBeUpdated,
              status: ReservationStatus.RESERVED,
            };

            await reservationService.updateReservationStatus(updateData);
            getReservationListByHotelOwnerAddress(hotelOwnerAddress).then(
              (updatedList) => {
                if (updatedList && updatedList.length > 0) {
                  setReservationList(updatedList);
                  store.dispatch(setShowScreenLoader(false));
                }
              }
            );
          } else {
            setReservationList(res);
            store.dispatch(setShowScreenLoader(false));
          }
        } else {
          setReservationList([]);
          store.dispatch(setShowScreenLoader(false));
        }
      })
      .catch((e) => {
        store.dispatch(setShowScreenLoader(false));
        console.log(e);
      });
  }, []);

  return (
    <>
      <Container style={{ minHeight: "55vh" }}>
        <Row>
          <Col lg={10}>
            <div
              className="page-header mt-4"
              style={{
                color: "rgb(44 44 118)",
                fontWeight: 700,
                fontSize: "50px",
                marginBottom: "20px",
              }}
            >
              Manage Bookings
            </div>
          </Col>
        </Row>
        {reservationList.length > 0 ? (
          <Row className="mt-3">
            <Col>
              <Table striped bordered>
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th>Customer Name</th>
                    <th>Contact Number</th>
                    <th>Hotel</th>
                    <th>From</th>
                    <th>To</th>
                    <th>No. of nights</th>
                    <th>Room details</th>
                    <th>Price ({process.env.REACT_APP_CURRENCY})</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {reservationList.map((rs) => {
                    const now = new Date();
                    const cancelAfterTime = new Date(rs.CancelAfterTime);
                    const isSocialLogin =
                      localStorage.getItem(LocalStorageKeys.LoginMethod) ===
                      LoginMethods.SOCIAL;
                    return (
                      <tr
                        key={rs.Id}
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        <td style={{ width: "10%" }}>{rs.CustomerName}</td>
                        <td style={{ width: "10%" }}>{rs.Telephone}</td>
                        <td style={{ width: "10%" }}>{rs.Hotel}</td>
                        <td style={{ width: "10%" }}>{rs.FromDate}</td>
                        <td style={{ width: "10%" }}>{rs.ToDate}</td>
                        <td style={{ width: "8%" }}>{rs.NoOfNights}</td>

                        <td style={{ width: "15%" }}>
                          <Card
                            style={{
                              backgroundColor: "#ffffff00",
                              border: "solid #c4c4c4 1px",
                            }}
                          >
                            <Card.Body
                              style={{
                                backgroundColor: "#ffffff00",
                                padding: "5px",
                              }}
                            >
                              {rs.Rooms.map((rm) => {
                                return (
                                  <Row key={rm.RoomTypeId}>
                                    <Col>
                                      {rm.Code} &nbsp;&nbsp;&nbsp;&nbsp;
                                      {rm.NoOfRooms}
                                    </Col>
                                  </Row>
                                );
                              })}
                            </Card.Body>
                          </Card>
                        </td>
                        <td style={{ width: "10%" }} title={`${rs.Price} XRP`}>
                          {(rs.Price * 115000).toFixed(2)}
                        </td>
                        <td style={{ width: "8%" }}>{rs.Status}</td>
                        <td style={{ width: "15%" }}>
                          <Row>
                            {rs.Status === ReservationStatus.RESERVED ? (
                              <Col md={12}>
                                <Row>
                                  <Button
                                    className="complete_btn"
                                    onClick={() =>
                                      handleAcceptance(
                                        rs.Id,
                                        rs.FromDate,
                                        rs.EscrowSequence,
                                        rs.CustomerWalletAddress
                                      )
                                    }
                                    disabled={
                                      new Date(rs.FromDate) < new Date() ||
                                      rs.Status !== ReservationStatus.RESERVED
                                    }
                                    title="CheckIn"
                                  >
                                    {" "}
                                    CheckIn
                                    {/* <FontAwesomeIcon
                                        size="sm"
                                        icon={faCheck}
                                        className="fa fa-check"
                                        title="Accept Reservation"
                                      /> */}
                                  </Button>
                                  {/* <Col md={6}>
                                    <Button
                                      className="button-cancel-outline"
                                      onClick={() =>
                                        openRejectionModal(
                                          rs.Id,
                                          rs.FromDate,
                                          rs.EscrowSequence,
                                          rs.CustomerWalletAddress
                                        )
                                      }
                                      disabled={
                                        new Date(rs.FromDate) < new Date() ||
                                        rs.Status !==
                                          ReservationStatus.RESERVED ||
                                        (isSocialLogin && now < cancelAfterTime)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        size="sm"
                                        icon={faTimes}
                                        className="fa fa-times"
                                        title="Reject Reservation"
                                      />
                                    </Button>
                                  </Col> */}
                                </Row>
                              </Col>
                            ) : (
                              <Button
                                className="complete_btn"
                                title="Complete Reservation"
                                onClick={() => openCompletionModal(rs.Id)}
                                disabled={
                                  rs.Status !== ReservationStatus.ACCEPTED
                                }
                              >
                                Complete
                              </Button>
                            )}
                          </Row>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={10}>
              <div
                className=" mt-4"
                style={{
                  color: "rgb(44 44 118)",
                  fontWeight: 500,
                  fontSize: "15px",
                }}
              >
                No reservations currently.
              </div>
            </Col>
          </Row>
        )}
        <ConfirmationModal
          show={showRejectionModal || showCompletionModal}
          title={modalText}
          handleClose={closeModal}
          handleCancel={showRejectionModal ? handleRejection : handleCompletion}
        />
      </Container>
    </>
  );
};

export default ManageBookings;
