import ContractService from "../services-common/contract-service";
import XrplService from "../services-common/xrpl-service";
const constants = require("./../constants");

export default class UserService {
  static instance = UserService.instance || new UserService();
  contractService = ContractService.instance;
  #xrplService = XrplService.xrplInstance;

  async init() {
    await this.contractService.init();
    await this.#xrplService.init();
  }

  async registerUser(data) {
    const submitObject = {
      type: constants.RequestTypes.USER,
      subType: constants.RequestSubTypes.REGISTER_USER,
      data: data,
    };

    try {
      const res = await this.contractService.submitInputToContract(
        submitObject
      );
      return res;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getUserByWalletAddress(walletAddress) {
    const submitObject = {
      type: constants.RequestTypes.USER,
      subType: constants.RequestSubTypes.GET_USER_BY_WALLET_ADDRESS,
      data: {
        walletAddress: walletAddress,
      },
    };

    try {
      const res = await this.contractService.submitReadRequest(submitObject);
      console.log("retrieved user:", res);
      return res;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}
