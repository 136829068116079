import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPaymentSelectionTopUpSocialOpen: false,
};

const PaymentSelectionTopUpSocialSlice = createSlice({
  name: "paymentSelectionTopUpSocial",
  initialState,
  reducers: {
    openPaymentSelectionTopUpSocial: (state) => {
      state.isPaymentSelectionTopUpSocialOpen = true;
    },
    closePaymentSelectionTopUpSocial: (state) => {
      state.isPaymentSelectionTopUpSocialOpen = false;
    },
  },
});

export const {
  openPaymentSelectionTopUpSocial,
  closePaymentSelectionTopUpSocial,
} = PaymentSelectionTopUpSocialSlice.actions;
export default PaymentSelectionTopUpSocialSlice.reducer;
