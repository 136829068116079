import { useEffect, useState } from "react";
import { Table, Badge, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faClock } from "@fortawesome/free-solid-svg-icons";
import { TransactionTypes } from "../../constants/constants";

const PaginatedTable = ({
  mappingTransactions,
  accountAddress,
  currencyType,
}) => {
  const itemsPerPage = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  console.log("mappingTransactions", mappingTransactions);

  const getStatusXRP = (tx) => {
    const destinationAddress = tx.Destination;
    const transactionType = tx.TransactionType;
    if (transactionType === TransactionTypes.PAYMENT) {
      if (tx.Account === accountAddress) {
        return (
          <Badge bg="danger">
            SENT{" "}
            <FontAwesomeIcon
              icon={faArrowUp}
              style={{ transform: "rotate(45deg)" }}
            />
          </Badge>
        );
      } else if (destinationAddress === accountAddress) {
        return (
          <Badge bg="success">
            RECEIVED{" "}
            <FontAwesomeIcon
              icon={faArrowUp}
              style={{ transform: "rotate(225deg)" }}
            />
          </Badge>
        );
      }
    }

    if (transactionType === TransactionTypes.ESCROW_CREATE) {
      if (tx.Account === accountAddress) {
        return (
          <Badge bg="danger">
            SENT{" "}
            <FontAwesomeIcon
              icon={faArrowUp}
              style={{ transform: "rotate(45deg)" }}
            />
          </Badge>
        );
      } else {
        return (
          <Badge bg="warning">
            PENDING <FontAwesomeIcon icon={faClock} />
          </Badge>
        );
      }
    }

    if (transactionType === TransactionTypes.ESCROW_FINISH) {
      return (
        <Badge bg="success">
          RECEIVED{" "}
          <FontAwesomeIcon
            icon={faArrowUp}
            style={{ transform: "rotate(225deg)" }}
          />
        </Badge>
      );
    }
  };

  // Calculate total pages
  const totalPages = Math.ceil(mappingTransactions.length / itemsPerPage);

  // Get current page's transactions
  const currentTransactions = mappingTransactions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [mappingTransactions]);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatAmount = (amount) => {
    if (typeof amount === "string") {
      const value = parseFloat(amount / 1000000).toLocaleString(undefined, {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      });
      const returnValue = currencyType.xrp ? `${value} XRP` : `${amount} TPQ`;
      return returnValue;
    } else if (!amount) {
    } else {
      const value = parseFloat(amount).toLocaleString(undefined, {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      });
      const returnValue = currencyType.xrp ? `${value} XRP` : `${amount} TPQ`;
      return returnValue;
    }
  };

  const formatAndGetDate = (timestamp) => {
    const millisecondsSinceUnixEpoch = (timestamp + 946684800) * 1000;
    const dateObject = new Date(millisecondsSinceUnixEpoch);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();

    return `${year}-${month}-${day}`;
  };

  const formatAndGetTime = (timestamp) => {
    const millisecondsSinceUnixEpoch = (timestamp + 946684800) * 1000;
    const dateObject = new Date(millisecondsSinceUnixEpoch);

    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };
    const time = dateObject.toLocaleTimeString("en-US", timeOptions);

    return time;
  };

  return (
    <>
      <Table striped={true} bordered={true}>
        <thead>
          <tr>
            <th className="center-text">Status</th>
            <th className="center-text">Date</th>
            <th className="center-text">Time</th>
            <th className="center-text">Source</th>
            <th className="center-text">Destination</th>
            <th className="center-text">Type</th>
            <th className="center-text">Amount</th>
          </tr>
        </thead>
        <tbody>
          {currentTransactions.map((tx, index) => {
            return (
              <tr key={index}>
                <td>
                  <h5 className="mb-0 center-text">
                    {getStatusXRP(tx) || "-"}
                  </h5>
                </td>

                {/* DATE */}
                <td>
                  <div
                    className="cell-texts"
                    style={{ color: "rgb(44 44 118)" }}
                  >
                    {tx.date ? formatAndGetDate(tx.date) : "-"}
                  </div>
                </td>

                {/* TIME */}
                <td>
                  <div
                    className="cell-texts"
                    style={{ color: "rgb(44 44 118)" }}
                  >
                    {tx.date ? formatAndGetTime(tx.date) : "-"}
                  </div>
                </td>

                {/* SOURCE */}
                <td className="center-text">
                  <div
                    className="cell-texts"
                    style={{ color: "rgb(44 44 118)" }}
                  >
                    {tx.TransactionType === TransactionTypes.ESCROW_FINISH
                      ? tx.Owner
                      : tx.Account || "-"}
                  </div>
                </td>

                {/* DESTINATION */}
                <td className="center-text">
                  <div
                    className="cell-texts"
                    style={{ color: "rgb(44 44 118)" }}
                  >
                    {tx.TransactionType === TransactionTypes.ESCROW_FINISH
                      ? tx.Account
                      : tx.Destination || "-"}
                  </div>
                </td>

                {/* TYPE */}
                <td>
                  <div
                    className="cell-texts center-text"
                    style={{ color: "rgb(44 44 118)" }}
                  >
                    {tx.TransactionType ? tx.TransactionType : "-"}
                  </div>
                </td>

                {/* AMOUNT */}
                <td className="center-text">
                  <div
                    className={`cell-texts ${
                      tx.Account === accountAddress ? "text-red" : "text-green"
                    } `}
                  >
                    {tx.Amount ? formatAmount(tx.Amount) : "-"}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* Pagination controls */}
      {/* {totalPages > 1 && ( */}
      <Pagination className="justify-content-center">
        <Pagination.First onClick={() => handlePageChange(1)} />
        <Pagination.Prev
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
        />
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() =>
            handlePageChange(Math.min(totalPages, currentPage + 1))
          }
        />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} />
      </Pagination>
      {/* )} */}
    </>
  );
};

export default PaginatedTable;
