import { FaTimes } from "react-icons/fa";
import "./TopUpModal.scss";
import { useEffect, useState } from "react";
import { showPayQRWindow } from "../../services-common/xumm-api-service";
import toast from "react-hot-toast";
import { LocalStorageKeys } from "../../constants/constants";
import { createTrustLineSocial } from "../../services-common/web3auth-service";

const TopUpModal = ({ onClose, isUserAccountActivated }) => {
  const [fromAccount, setFromAccount] = useState("");
  const [amount, setAmount] = useState("");
  const toAccountNumber = localStorage.getItem(LocalStorageKeys.AccountAddress);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!amount || amount <= 0) {
      toast.error("Please enter a valid amount.");
      setIsLoading(false);
      return;
    }

    if (!isUserAccountActivated && amount < 10) {
      toast.error("Minimum top-up amount is 10 XRP.");
      setIsLoading(false);
      return;
    }

    try {
      const result = await showPayQRWindow(
        fromAccount,
        toAccountNumber,
        amount,
        null, // finishAfterTime (if needed)
        null, // cancelAfterTime (if needed)
        1002, // destinationTag (if needed)
        "XRP", // currency
        null, // issuer (if needed)
        "Payment", // transactionType
        0 // escrowSequence (default 0)
      );

      if (
        result &&
        (result.result.meta?.TransactionResult === "tesSUCCESS" ||
          result.result.accepted === true)
      ) {
        const answer = await createTrustLineSocial(toAccountNumber);
        toast.success("Transaction successful!");
      } else {
        toast.error("Transaction failed");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred during the transaction.");
    } finally {
      localStorage.setItem(LocalStorageKeys.TransactionInProgress, "false");
      setIsLoading(false);
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const handleAmountFieldChange = (e) => {
    const inputValue = e.target.value;

    // Regular expression to check if the value is a positive decimal number
    const isPositiveDecimal =
      /^\d*\.?\d*$/.test(inputValue) &&
      (inputValue === "" || parseFloat(inputValue) > 0);

    if (isPositiveDecimal) {
      setAmount(inputValue);
    }
  };

  const hadnleClick = (e) => {
    if (isLoading) return;
    onClose();
  };

  return (
    <section className="top-up-modal-overlay" onClick={hadnleClick}>
      <form
        className="top-up-modal"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
        <div className="modal-title">Transaction</div>
        <div className="modal-content">
          <label>
            Sender Account
            <input
              type="text"
              value={fromAccount}
              required
              placeholder="type sender account address here..."
              onChange={(e) => setFromAccount(e.target.value)}
            />
          </label>
          <label>
            Receiver Account
            <input
              type="text"
              value={toAccountNumber}
              placeholder="type receiver account address here..."
              required
              disabled
            />
          </label>
          <label>
            Amount
            <input
              type="number"
              value={amount}
              placeholder="Enter amount"
              min="0" // Ensures only positive values can be entered
              step="any" // Allows for decimal values, if needed
              required
              onChange={handleAmountFieldChange}
            />
          </label>
        </div>
        <div className="modal-actions">
          <button className="submit-button" disabled={isLoading}>
            {isLoading ? "Processing..." : "Submit"}
          </button>
        </div>
      </form>
    </section>
  );
};

export default TopUpModal;
