import { Xumm } from "xumm";
import {
  LocalStorageKeys,
  PaymentResults,
  TransactionTypes,
} from "../constants/constants";
import {
  loginSuccessfully,
  logoutSuccessfully,
} from "../redux/LoginState/LoginStateSlice";
import { store } from "../redux/store";
import { setShowScreenLoader } from "../redux/screenLoader/ScreenLoaderSlice";
import toast from "react-hot-toast";
import { getAccounts, initWeb3Auth } from "./web3auth-service";
import { xrpToDrops, Wallet } from "xrpl";
import UserService from "../services-domain/user-service-copy";

const xrpl = require("xrpl");

let xumm = null;
const client = new xrpl.Client(process.env.REACT_APP_XRPL_WS_URL);

// Client connection
const connectClient = async () => {
  try {
    await client.connect();
    console.log("Connected to XRPL WebSocket client");
  } catch (error) {
    console.error("Error connecting to XRPL WebSocket client:", error);
    toast.error("Failed to connect to XRPL WebSocket client");
  }
};
connectClient();

export async function xummAuthorize() {
  xumm = new Xumm(process.env.REACT_APP_XUMM_APIKEY);
  const userService = new UserService();
  const loginState = localStorage.getItem("loginState");
  const transactionInProgress =
    localStorage.getItem(LocalStorageKeys.TransactionInProgress) === "true";
  const isEscrowCreateInProgress =
    localStorage.getItem(LocalStorageKeys.EscrowCreateInProgress) === "true";
  if (loginState) {
    const parsedLoginState = JSON.parse(loginState);

    // Check the isLoggedIn property on the parsed object
    if (parsedLoginState.isLoggedIn === true && !transactionInProgress) return;
  }
  try {
    const res = await xumm.authorize();
    console.log("res:", res);
    if (res && res.me) {
      const existingAddess = localStorage.getItem(
        LocalStorageKeys.AccountAddress
      );
      localStorage.removeItem(LocalStorageKeys.EscrowCreateInProgress);
      if (isEscrowCreateInProgress) return res.me.account;
      if (existingAddess && existingAddess !== res.me.account) return;
      store.dispatch(setShowScreenLoader(true));
      const responseGetUser = await userService.getUserByWalletAddress(
        res.me.account
      );
      if (!responseGetUser) {
        // initiate trust line
        try {
          const isTrustLineInitiated = await createTrustLineXRPL(
            res.me.account
          );

          if (isTrustLineInitiated) {
            // Register the user if not already registered
            userService.registerUser({
              walletAddress: res.me.account,
              loginMethod: "XRPL",
            });
          }
        } catch (e) {
          console.log("error", e);
          throw e;
        }
      }
      localStorage.setItem(LocalStorageKeys.AccountAddress, res.me.account);
      store.dispatch(loginSuccessfully(res.me.account));
      store.dispatch(setShowScreenLoader(false));
      return true;
    } else {
      throw new Error("Failed to authorize");
    }
  } catch (e) {
    throw e;
  }
}

export async function xummLogout() {
  localStorage.clear();
  store.dispatch(logoutSuccessfully());
  try {
    await xumm.logout();
    xumm = null;
  } catch (e) {
    console.log(e);
  }
}

export async function createPayloadAndSubmit(
  sourceAccount,
  destinationAccount,
  callback,
  amount,
  currency = null,
  issuer = null
) {
  let amountField = String(amount * 1000000);
  if (currency && issuer) {
    amountField = {
      value: String(amount),
      currency: currency,
      issuer: issuer,
    };
  }

  const payload = await xumm.payload?.createAndSubscribe(
    {
      TransactionType: "Payment",
      Destination: destinationAccount,
      Account: sourceAccount,
      Amount: amountField,
    },
    callback
  );

  return payload;
}

export async function subscribe(createdPayload) {
  return await xumm.payload?.subscribe(createdPayload);
}

/**
 *  Use this method for Payments
 * @param sourceAddress
 * @param destinationAddress
 * @param amount
 * @param finishTime
 * @param destinationTag Number | greater than 1000 to represent the reason
 * @param currency
 * @param issuer
 * @returns {Promise<constants.PaymentResults>}
 */
export async function showPayQRWindow(
  sourceAddress,
  destinationAddress,
  amount,
  finishAfterTime,
  cancelAfterTime,
  destinationTag,
  currency,
  issuer,
  transactionType,
  escrowSequence = 0
) {
  let payload = null;
  localStorage.setItem(LocalStorageKeys.TransactionInProgress, "true");

  // Create payload for the transaction
  if (transactionType === TransactionTypes.PAYMENT) {
    const isSocialLogin =
      localStorage.getItem(LocalStorageKeys.LoginMethod) === "Social";
    let accountNumber;
    let accounts;
    try {
      accounts = await getAccounts();
      if (accounts && accounts.length > 0) {
        accountNumber = accounts[0];
      }
    } catch (e) {
      console.log("failed to fetch accounts");
    }
    const loggedInAccNumber = localStorage.getItem(
      LocalStorageKeys.AccountAddress
    );
    if (
      isSocialLogin &&
      accountNumber === loggedInAccNumber &&
      loggedInAccNumber === sourceAddress
    ) {
      try {
        let web3auth = await initWeb3Auth(
          localStorage.getItem(LocalStorageKeys.LoginMethod)
        );
        if (!web3auth) {
          throw new Error("failed to initialize web3auth");
        }
        if (accounts && accounts.length > 0) {
          const lastLedgerSequence = await getLastLedgerSequence();

          const tx = {
            TransactionType: "Payment",
            Account: accounts[0],
            Amount: xrpToDrops(amount),
            Destination: destinationAddress,
            LastLedgerSequence: lastLedgerSequence,
          };

          const payed_resp = await web3auth.provider.request({
            method: "xrpl_submitTransaction",
            params: {
              transaction: tx,
            },
          });
          return payed_resp;
        } else {
          console.log("failed to fetch accounts");
          throw new Error("failed to fetch accounts");
        }
      } catch (error) {
        console.log("error", error);
        throw error;
      }
    } else {
      if (xumm) {
        await xumm.logout();
      }
      await xummAuthorize();
      const createdPayload = await createTransactionPayload(
        sourceAddress,
        destinationAddress,
        amount,
        destinationTag,
        currency,
        issuer,
        xumm
      );

      // Open the payload in the XUMM app (QR code can be scanned)
      const payloadWindow = window.open(createdPayload.next.always);

      // Poll the XUMM API for the payload status
      let signed = false;
      while (!signed) {
        const result = await xumm.payload.get(createdPayload.uuid);
        if (result.meta.signed === true) {
          signed = true;

          // Close the opened window
          if (payloadWindow) {
            payloadWindow.close();
          }

          payload = result;
        } else if (
          result.meta.signed === false &&
          result.meta.cancelled === true
        ) {
          console.log("Transaction was not signed or was cancelled");
          if (payloadWindow) {
            payloadWindow.close();
            break;
          }
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      // Submit the signed transaction to XRPL
      if (payload && payload.response.hex) {
        const tx = await client.submitAndWait(payload.response.hex);
        console.log(
          "Balance changes:",
          JSON.stringify(xrpl.getBalanceChanges(tx.result.meta), null, 2)
        );
        return tx;
      }
    }
  } else if (transactionType === TransactionTypes.ESCROW_CREATE) {
    const finishAfterRipple = xrpl.isoTimeToRippleTime(finishAfterTime);
    const cancelAfterRipple = xrpl.isoTimeToRippleTime(cancelAfterTime);
    const lastLedgerSequence = await getLastLedgerSequence();
    const accountSequence = await getAccountSequence(sourceAddress);
    const isInternalWalletTransaction =
      localStorage.getItem(LocalStorageKeys.PayWithInternalWallet) === "true";
    let accounts;
    try {
      accounts = await getAccounts();
    } catch (e) {
      throw e;
    }
    const loggedInAccNumber = localStorage.getItem(
      LocalStorageKeys.AccountAddress
    );
    if (isInternalWalletTransaction && loggedInAccNumber === sourceAddress) {
      console.log("inside internal wallet");
      let web3auth = await initWeb3Auth(
        localStorage.getItem(LocalStorageKeys.LoginMethod)
      );
      if (!web3auth) {
        throw new Error("failed to initialize web3auth");
      }
      if (accounts && accounts.length > 0) {
        const tx = {
          TransactionType: "EscrowCreate",
          Account: sourceAddress,
          Destination: destinationAddress,
          Amount: xrpl.xrpToDrops(amount),
          FinishAfter: finishAfterRipple,
          CancelAfter: cancelAfterRipple,
          Sequence: accountSequence,
          LastLedgerSequence: lastLedgerSequence,
        };
        const originalResponse = await web3auth.provider.request({
          method: "xrpl_submitTransaction",
          params: {
            transaction: tx,
          },
        });
        const rippleTimeToIsoTime = (rippleTime) => {
          const unixTime = (rippleTime + 0x386d4380) * 1000; // Ripple Epoch to Unix Epoch
          return new Date(unixTime).toISOString();
        };
        const userInfo = await web3auth.getUserInfo();
        const newObject = {
          sequence: originalResponse.result.tx_json.Sequence,
          issuedUserToken: userInfo.verifierId,
          finishAfterTime: rippleTimeToIsoTime(
            originalResponse.result.tx_json.FinishAfter
          ),
          cancelAfterTime: rippleTimeToIsoTime(
            originalResponse.result.tx_json.CancelAfter
          ),
          transactionResult: originalResponse.result.engine_result,
        };
        return newObject;
      } else {
        toast.error("failed to fetch accounts. Please try again");
        console.log("failed to fetch accounts");
        throw new Error("failed to fetch accounts");
      }
    } else {
      console.log("inside xumm login");
      localStorage.setItem(LocalStorageKeys.EscrowCreateInProgress, "true");
      await xumm.logout();
      const senderAddress = await xummAuthorize();
      payload = await createEscrow(
        senderAddress,
        destinationAddress,
        amount,
        finishAfterRipple,
        cancelAfterRipple,
        currency,
        issuer
      );
    }
  } else if (transactionType === TransactionTypes.ESCROW_CANCEL) {
    payload = await cancelEscrow(escrowSequence, sourceAddress);
  } else if (transactionType === TransactionTypes.ESCROW_FINISH) {
    console.log("inside escrow finish");

    // Hard coded to show TPQ value deduction, only for demo purpose
    const tpqAmount = localStorage.getItem(LocalStorageKeys.TPQValue);
    localStorage.setItem(LocalStorageKeys.TPQValue, tpqAmount - 2);

    const lastLedgerSequence = await getLastLedgerSequence();
    const accountSequence = await getAccountSequence(destinationAddress);
    const isSocialLoginAccount =
      localStorage.getItem(LocalStorageKeys.LoginMethod) === "Social";
    let accountNumber;
    let accounts;
    try {
      accounts = await getAccounts();
      console.log("accounts", accounts);
      if (accounts && accounts.length > 0) {
        console.log("accounts[0]", accounts[0]);
        accountNumber = accounts[0];
      }
    } catch (e) {
      throw e;
    }
    const loggedInAccNumber = localStorage.getItem(
      LocalStorageKeys.AccountAddress
    );
    if (isSocialLoginAccount && accountNumber === loggedInAccNumber) {
      console.log("inside social login");
      let web3auth = await initWeb3Auth(
        localStorage.getItem(LocalStorageKeys.LoginMethod)
      );
      if (!web3auth) {
        throw new Error("failed to initialize web3auth");
      }
      if (accounts && accounts.length > 0) {
        const tx = {
          TransactionType: "EscrowFinish",
          Account: destinationAddress,
          Owner: sourceAddress,
          OfferSequence: escrowSequence,
          Sequence: accountSequence,
          LastLedgerSequence: lastLedgerSequence,
        };
        const originalResponse = await web3auth.provider.request({
          method: "xrpl_submitTransaction",
          params: {
            transaction: tx,
          },
        });
        console.log("originalResponse", originalResponse);
        const userInfo = await web3auth.getUserInfo();
        const newObject = {
          sequence: originalResponse.result.tx_json.Sequence,
          issuedUserToken: userInfo.verifierId,
          finishAfterTime: "",
          cancelAfterTime: "",
          transactionResult: originalResponse.result.engine_result,
        };
        return newObject;
      } else {
        console.log("failed to fetch accounts");
        throw new Error("failed to fetch accounts");
      }
    } else {
      console.log("inside xumm login");
      localStorage.setItem(LocalStorageKeys.EscrowCreateInProgress, "true");
      await xumm.logout();
      const senderAddress = await xummAuthorize();
      payload = await finishEscrow(
        escrowSequence,
        senderAddress,
        sourceAddress
      );
    }
  } else {
    payload = await createTransactionPayload(
      sourceAddress,
      destinationAddress,
      amount,
      destinationTag,
      currency,
      issuer
    );
  }

  if (!payload) {
    console.log("Incorrect transaction type");
    toast.error("Incorrect transaction type");
    return;
  }
  const subscription = await subscribe(payload);

  const newWindow = window.open(
    payload.next.no_push_msg_received,
    "Xaman Pay",
    "height=750,width=600, right=300, resizable=no, left=300,top=100"
  );

  store.dispatch(setShowScreenLoader(false));
  const tmout = setInterval(() => {
    if (newWindow.closed) {
      subscription.resolve(PaymentResults.ABORTED);
    }
  }, 1000);

  subscription.websocket.onmessage = async (message) => {
    const data = JSON.parse(message.data.toString());

    if (Object.keys(data).indexOf("signed") > -1 && data.signed) {
      let escrowSequence = 0;
      let issuedUserToken = "";
      let result = "";

      const transactionResult = await xumm.payload.get(payload.uuid);
      issuedUserToken = transactionResult.application.issued_user_token;

      if (transactionResult.response.txid) {
        const txDetails = await client.request({
          command: "tx",
          transaction: transactionResult.response.txid,
        });

        if (txDetails && txDetails.result && txDetails.result.Sequence) {
          escrowSequence = txDetails.result.Sequence;
          result = txDetails.result.meta.TransactionResult;
        }
      }
      const response = {
        sequence: escrowSequence,
        issuedUserToken: issuedUserToken,
        finishAfterTime: finishAfterTime,
        cancelAfterTime: cancelAfterTime,
        transactionResult: result,
      };
      subscription.resolve(response);

      return data;
    } else if (Object.keys(data).indexOf("signed") > -1 && !data.signed) {
      subscription.resolve(PaymentResults.REJECTED);
      return data;
    }
  };

  const paymentResult = await subscription.resolved;
  clearInterval(tmout);
  newWindow.close();
  console.log(paymentResult);
  return paymentResult;
}

async function getAccountSequence(account) {
  if (!client.isConnected()) {
    await connectClient();
  }
  const accountInfo = await client.request({
    command: "account_info",
    account: account,
  });
  return accountInfo.result.account_data.Sequence;
}

// Function to get a sufficiently high LastLedgerSequence
async function getLastLedgerSequence() {
  if (!client.isConnected()) {
    await connectClient();
  }
  const ledger = await client.request({
    command: "ledger",
    ledger_index: "validated",
  });
  return ledger.result.ledger_index + 50; // Add a reasonable buffer to the current ledger index
}

// Function to submit a transaction with retry logic
async function submitTransactionWithRetry(signedTx, retryCount = 5) {
  let lastError;
  for (let i = 0; i < retryCount; i++) {
    try {
      if (!client.isConnected()) {
        await connectClient();
      }
      const result = await client.submitAndWait(signedTx.tx_blob);
      return result;
    } catch (error) {
      lastError = error;
      if (error.data && error.data.error === "temBAD_EXPIRATION") {
        console.log("Retrying transaction due to temBAD_EXPIRATION...");
        continue;
      } else {
        throw error;
      }
    }
  }
  throw lastError;
}

// Create transaction payload
async function createTransactionPayload(
  sourceAddress,
  destinationAddress,
  amount,
  destinationTag = 1000,
  currency = null,
  issuer = null,
  newXumm
) {
  if (!client.isConnected()) {
    await connectClient();
  }
  let amountField = String(amount * 1000000);
  if (currency && issuer) {
    amountField = {
      value: String(amount),
      currency: currency,
      issuer: issuer,
    };
  }
  const lastLedgerSequence = await getLastLedgerSequence();
  const accountSequence = await getAccountSequence(sourceAddress);
  const tx = {
    TransactionType: "Payment",
    Destination: destinationAddress,
    Account: sourceAddress,
    Amount: amountField,
    DestinationTag: destinationTag,
    Sequence: accountSequence,
    LastLedgerSequence: lastLedgerSequence,
  };
  return await newXumm.payload?.create(tx);
}

// Create Escrow
async function createEscrow(
  sourceAddress,
  destinationAddress,
  amount,
  finishAfterRipple,
  cancelAfterRipple,
  currency = null,
  issuer = null
) {
  let amountField = String(amount * 1000000);
  if (currency && issuer) {
    amountField = {
      value: String(amount),
      currency: currency,
      issuer: issuer,
    };
  }
  const lastLedgerSequence = await getLastLedgerSequence();
  const accountSequence = await getAccountSequence(sourceAddress);

  const tx = {
    TransactionType: "EscrowCreate",
    Account: sourceAddress,
    Destination: destinationAddress,
    Amount: xrpl.xrpToDrops(amount),
    FinishAfter: finishAfterRipple,
    CancelAfter: cancelAfterRipple,
    Sequence: accountSequence,
    LastLedgerSequence: lastLedgerSequence,
  };

  return await xumm.payload?.create(tx);
}

// Finish Escrow
async function finishEscrow(escrowSequence, destinationAddress, sourceAddress) {
  const lastLedgerSequence = await getLastLedgerSequence();
  const accountSequence = await getAccountSequence(destinationAddress);

  const tx = {
    TransactionType: "EscrowFinish",
    Account: destinationAddress,
    Owner: sourceAddress,
    OfferSequence: escrowSequence,
    Sequence: accountSequence,
    LastLedgerSequence: lastLedgerSequence,
  };

  return await xumm.payload?.create(tx);
}

// Cancel Escrow
async function cancelEscrow(escrowSequence, sourceAddress) {
  const lastLedgerSequence = await getLastLedgerSequence();
  const accountSequence = await getAccountSequence(sourceAddress);

  const tx = {
    TransactionType: "EscrowCancel",
    Account: sourceAddress,
    Owner: sourceAddress,
    OfferSequence: escrowSequence,
    Sequence: accountSequence,
    LastLedgerSequence: lastLedgerSequence,
  };

  return await xumm.payload?.create(tx);
}

export const getAllXRPTransactions = async (account) => {
  try {
    if (!client.isConnected()) {
      await connectClient();
    }
    const transactions = await client.request({
      command: "account_tx",
      account: account,
    });
    const responseArray = transactions.result.transactions.map((tx) => tx.tx);
    let returnXRPArray = [];
    let returnTPQArray = [];
    responseArray.forEach((tx) => {
      if (tx.Amount instanceof Object) {
        tx.Amount = tx.Amount.value;
        returnTPQArray.push(tx);
      } else {
        returnXRPArray.push(tx);
      }
    });
    returnXRPArray = returnXRPArray.filter(
      (tx) => tx.TransactionType !== TransactionTypes.TRUST_SET
    );
    const returnObject = {
      xrp: returnXRPArray,
      tpq: returnTPQArray,
    };
    return returnObject;
  } catch (err) {
    throw err;
  }
};

export const getAccountBalance = async (account) => {
  const TPQ_HEX = process.env.REACT_APP_TPQ_CURRENCY_HEX;
  try {
    // Ensure the client is connected
    if (!client.isConnected()) {
      await connectClient();
    }

    // Fetch account info to get the XRP balance
    const accountInfo = await client.request({
      command: "account_info",
      account: account,
      ledger_index: "validated",
    });

    const accountLines = await client.request({
      command: "account_lines",
      account: account,
      ledger_index: "validated",
    });

    const lines = accountLines?.result?.lines;
    let tpqBalance = 0;

    if (lines && lines.length > 0) {
      const tpqLine = lines.find((line) => line.currency === TPQ_HEX);
      if (tpqLine) {
        tpqBalance = tpqLine.balance;
      }
    }

    const balanceInDrops = accountInfo.result.account_data.Balance;
    const balanceInXRP = parseFloat(balanceInDrops / 1000000).toFixed(6);

    // Fetch the current XRP to USD exchange rate
    const response = await fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=ripple&vs_currencies=usd"
    );
    const data = await response.json();
    const xrpToUsdRate = data.ripple.usd;

    // Convert balance to USD
    const balanceInUSD = balanceInXRP * xrpToUsdRate;

    // Format balance to 2 decimal places
    const formattedBalanceInUSD = balanceInUSD.toFixed(2);
    console.log(balanceInXRP, formattedBalanceInUSD);
    return {
      xrp: balanceInXRP,
      usd: formattedBalanceInUSD,
      tpq: tpqBalance,
    };
  } catch (err) {
    throw err;
  }
};

export const cancelEscrowForSocial = async (res) => {
  let web3auth = await initWeb3Auth(
    localStorage.getItem(LocalStorageKeys.LoginMethod)
  );
  if (!web3auth) {
    throw new Error("failed to initialize web3auth");
  }

  const ownerAccAddress = res.WalletAddress
    ? res.WalletAddress
    : res.CustomerWalletAddress;

  const accountAddress = res.WalletAddress
    ? res.WalletAddress
    : localStorage.getItem(LocalStorageKeys.AccountAddress);

  const lastLedgerSequence = await getLastLedgerSequence();
  const accountSequence = await getAccountSequence(accountAddress);

  const tx = {
    TransactionType: "EscrowCancel",
    Account: accountAddress,
    Owner: ownerAccAddress,
    OfferSequence: res.EscrowSequence,
    Sequence: accountSequence,
    LastLedgerSequence: lastLedgerSequence + 4,
  };

  // 5. Submit the transaction
  const originalResponse = await web3auth.provider.request({
    method: "xrpl_submitTransaction",
    params: {
      transaction: tx,
    },
  });
  return originalResponse;
};

export function xummOff(event) {
  xumm.off(event);
}

async function createTrustLineXRPL(userAccountAddress) {
  const currency = process.env.REACT_APP_TPQ_CURRENCY_HEX;
  const issuerAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;
  const trustLineLimit = process.env.REACT_APP_TPQ_TRUSTLINE_LIMIT;

  if (!client.isConnected()) {
    await connectClient();
  }

  const transaction = {
    TransactionType: "TrustSet",
    Account: userAccountAddress,
    LimitAmount: {
      currency: currency,
      issuer: issuerAddress,
      value: trustLineLimit,
    },
  };

  const createdPayload = await xumm.payload.create(transaction);
  // const payloadWindow = window.open(createdPayload.next.always);
  const payloadWindow = window.open(
    createdPayload.next.no_push_msg_received,
    "Xaman Pay",
    "height=750,width=600, right=300, resizable=no, left=300,top=100"
  );
  let signed = false;
  let payload = null;

  while (!signed) {
    const result = await xumm.payload.get(createdPayload.uuid);
    if (result.meta.signed === true) {
      signed = true;

      // Close the opened window
      if (payloadWindow) {
        payloadWindow.close();
      }

      payload = result;
    } else if (result.meta.signed === false && result.meta.cancelled === true) {
      console.log("Transaction was not signed or was cancelled");
      if (payloadWindow) {
        payloadWindow.close();
        break;
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
  }

  // Submit the signed transaction to XRPL
  if (payload && payload.response.hex) {
    const result = await client.submitAndWait(payload.response.hex);
    if (result.result.meta.TransactionResult === "tesSUCCESS") {
      console.log("Trust Lines initiated.");
      return true;
    } else {
      console.error(
        `Failed to set trust line: ${result.result.meta.TransactionResult}`
      );
      return false;
    }
  }
  return false;
}

export const reduceBalanceFromXRPL = async () => {
  const TPQ_REDUCE_AMOUNT = 10;
  const destinationAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;
  const sourceAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);
  const lastLedgerSequence = await getLastLedgerSequence();
  const accountSequence = await getAccountSequence(sourceAddress);
  const currency = process.env.REACT_APP_TPQ_CURRENCY_HEX;
  const issuerAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;

  const tx = {
    TransactionType: "Payment",
    Amount: {
      currency: currency,
      issuer: issuerAddress,
      value: TPQ_REDUCE_AMOUNT,
    },
    Destination: destinationAddress,
    LastLedgerSequence: lastLedgerSequence,
  };
  const createdPayload = await xumm.payload?.create(tx);
  let payload = null;

  // Open the payload in the XUMM app (QR code can be scanned)
  const payloadWindow = window.open(createdPayload.next.always);

  // Poll the XUMM API for the payload status
  let signed = false;
  while (!signed) {
    const result = await xumm.payload.get(createdPayload.uuid);
    if (result.meta.signed === true) {
      signed = true;

      // Close the opened window
      if (payloadWindow) {
        payloadWindow.close();
      }

      payload = result;
    } else if (result.meta.signed === false && result.meta.cancelled === true) {
      console.log("Transaction was not signed or was cancelled");
      if (payloadWindow) {
        payloadWindow.close();
        break;
      }
      throw new Error("Transaction was not signed or was cancelled");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
  }
  // Submit the signed transaction to XRPL
  if (payload && payload.response.hex) {
    const result = await client.submitAndWait(payload.response.hex);
    console.log("result", result);
    return result;
  } else {
    throw new Error("Failed to earn the TPQ");
  }
};
