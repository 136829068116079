import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./styles.scss";

const ConfirmationModal = ({
  show,
  title,
  handleClose,
  handleCancel
}) => {
  const handleConfirm = () => {
    handleCancel();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="d-flex justify-content-center">
      <span className="font-weight-bold" style={{ marginTop: '20px', fontWeight: 'bold', fontSize: '20px' }}>
      {title}
    </span>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center w-100">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="confirm_button" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;